@mixin remove-horizontal-padding {
  padding-left: 0;
  padding-right: 0;
}

@mixin item-hover {
  @media (pointer: fine) {
    transition: transform ease 0.2s;

    &:hover {
      transform: translateY(-$spacing-xs);
    }
  }
}
